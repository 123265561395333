import * as React from "react";

const SectionTitle = (props) => {
    return (
        <div className="section-title-wrap">
            <p
                className={`section-title ${props.styleName || ""} ${
                    props.align === "right"
                        ? "section-title__right"
                        : "section-title__left"
                }`}
            >
                <span
                    className="title-bullet"
                    style={props.color ? { backgroundColor: props.color } : {}}
                ></span>
                <span className="title-text">{props.children}</span>
            </p>
        </div>
    );
};

export default SectionTitle;
