import * as React from "react";
import AppContext from "../../context/AppContext";

const ToggleMenuButton = (props) => {
    return (
        <AppContext.Consumer>
            {(appState) => {
                return (
                    <div
                        className={`toggle-menu-button-wrap ${
                            props.styleName || ""
                        }`}
                    >
                        <button
                            className={`toggle-menu-button ${
                                appState.isMenuOpen ? "is-open" : ""
                            }`}
                            aria-label="Menu"
                            onClick={appState.toggleMenu}
                        >
                            <span></span>
                            <span></span>
                            <span></span>
                        </button>
                    </div>
                );
            }}
        </AppContext.Consumer>
    );
};

export default ToggleMenuButton;
