import * as React from "react";
import animatedScrollSVG from "../../images/misc/animated-scroll.svg";
import animatedScrollBlackSVG from "../../images/misc/animated-scroll-black.svg";
const ScrollIcon = (props) => {
    let currentImage = props.styleName.includes("footer")
        ? animatedScrollBlackSVG
        : animatedScrollSVG;
    return (
        <div className={`scroll-icon-wrap ${props.styleName || ""}`}>
            <img
                className="scroll-icon-image"
                width="50"
                height="50"
                src={currentImage}
                alt="Scroll"
            />
        </div>
    );
};

export default ScrollIcon;
