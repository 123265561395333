import * as React from "react";

const FeaturedNavLink = (props) => {
    return (
        <div
            className={`featured-nav-link ${props.styleName || ""} ${
                props.valign === "top"
                    ? "featured-nav-link__top"
                    : "featured-nav-link__bottom"
            } ${props.halign === "right" ? "featured-nav-link__right" : ""}`}
        >
            <a href={"#" + props.hash} title={props.title || ""}>
                <span
                    className="bullet"
                    style={props.color ? { backgroundColor: props.color } : {}}
                ></span>
                <span className="title">
                    {props.children ? props.children : props.title}
                </span>
            </a>
        </div>
    );
};

export default FeaturedNavLink;
