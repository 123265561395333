import { useEffect } from "react";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export const useGsapReveal = () => {
    useEffect(() => {
        if (typeof window !== `undefined`) {
            gsap.registerPlugin(ScrollTrigger);
            gsap.core.globals("ScrollTrigger", ScrollTrigger);
        }
        // const animateFrom = (elem) => {
        //     let x = 0,
        //         y = -200;
        //     // if (elem.classList.contains("gsRevealFromLeft")) {
        //     //     x = -50;
        //     //     y = 0;
        //     // } else if (elem.classList.contains("gsRevalFromRight")) {
        //     //     x = 50;
        //     //     y = 0;
        //     // }

        //     gsap.fromTo(
        //         elem,
        //         { x, y, autoAlpha: 1 },
        //         {
        //             duration: 3,
        //             x: 0,
        //             y: 0,
        //             autoAlpha: 1,
        //             ease: "expo",
        //             overwrite: "auto",
        //         }
        //     );
        //     // gsap.from(elem, { x, y, duration: 2, autoAlpha: 0 });
        // };

        /* Fade in hero text when font is loaded */
        const heroText = document.getElementById("hero-text");
        const heroText2 = document.getElementById("hero-text-2");

        document.fonts.ready.then(function () {
            if (heroText) {
                heroText.classList.add("fadein");
            }
            if (heroText2) {
                heroText2.classList.add("fadein");
            }
        });

        const hide = (elem) => gsap.set(elem, { autoAlpha: 0 });

        let sectionTitleYFrom = {
            x: 0,
            y: window.innerWidth >= 768 ? -500 : -100,
            autoAlpha: window.innerWidth >= 768 ? 1 : 0,
        };
        gsap.utils.toArray(".section-title").forEach((elem) => {
            hide(elem);
        });
        ScrollTrigger.batch(".section-title", {
            start: "top bottom-=50px",
            once: true,
            // interval: 0.1, // time window (in seconds) for batching to occur
            onEnter: (batch) => {
                gsap.fromTo(batch, sectionTitleYFrom, {
                    duration: 2,
                    x: 0,
                    y: 0,
                    autoAlpha: 1,
                    ease: "expo",
                    overwrite: "auto",
                });
            },
        });

        // Initiate reveal
        gsap.utils.toArray(".js-reveal").forEach((elem) => {
            gsap.set(elem, {
                x: 0,
                y: 100,
                opacity: 0,
            });
        });
        ScrollTrigger.batch(".js-reveal", {
            start: "top bottom-=100px",
            // interval: 0.1, // time window (in seconds) for batching to occur
            onEnter: (batch) => {
                gsap.to(batch, {
                    opacity: 1,
                    y: 0,
                    // stagger: { each: 0.15 },
                    overwrite: true,
                });
            },
        });

        // Initiate reveal
        gsap.utils.toArray(".js-reveal-image").forEach((elem) => {
            gsap.set(elem, {
                x: 0,
                y: 0,
                opacity: 0,
                scale: 0.6,
            });
        });
        ScrollTrigger.batch(".js-reveal-image", {
            start: "top bottom-=50px",
            // interval: 0.1, // time window (in seconds) for batching to occur
            onEnter: (batch) => {
                gsap.to(batch, {
                    opacity: 1,
                    scale: 1,
                    // stagger: { each: 0.15 },
                    overwrite: true,
                });
            },
        });

        /* Sticky Menu */
        const toggleMenuButton = document.querySelector(".sticky-button");
        ScrollTrigger.create({
            trigger: ".stick-toggle-menu",
            start: "top top",
            onEnter: () => {
                toggleMenuButton.classList.add("is-stuck");
            },
            onLeaveBack: () => {
                toggleMenuButton.classList.remove("is-stuck");
            },
        });
    }, []);
};
